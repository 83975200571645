import { computed } from "@vue/composition-api";
import { v4 as uuid } from "uuid";

export type FormFieldTypeRegular =
  | "firstName"
  | "lastName"
  | "email"
  | "repeatEmail"
  | "phonePrefix"
  | "phoneNumber"
  | "gender"
  | "degree"
  | "specializations"
  | "licenseNumber"
  | "studentNumber"
  | "sponsor"
  | "country"
  | "addressCity"
  | "addressPostalCode"
  | "addressStreet"
  | "institutionName"
  | "institutionPosition"
  | "institutionCity"
  | "institutionPostalCode"
  | "institutionStreet"
  | "invoicePurchaser"
  | "invoiceName"
  | "invoiceStreet"
  | "invoicePostalCode"
  | "invoiceCity"
  | "invoiceEmail"
  | "invoiceVatNumber"
  | "invoiceConsent"
  | "invoiceProforma"
  | "electronicInvoiceConsent"
  | "consent"
  | "companion"
  | "sessionTopicsIds";

export type FormFieldTypeAdditional =
  | "phone"
  | "number"
  | "text"
  | "textarea"
  | "radioGroup"
  | "checkboxGroup"
  | "select"
  | "informationField";

export type FormGroupType =
  | "fields"
  | "invoice"
  | "consents"
  | "services"
  | "accommodation";

export type FormFieldType = FormFieldTypeRegular | FormFieldTypeAdditional;

export interface FormField {
  id: string;
  type: FormFieldType;
  name: string;
  label: string;
  options: {
    required: boolean;
    size: number;
    protectedName?: boolean;
    items?: Array<{
      id: string;
      text: string;
      value: string;
    }>;
  };
}

export interface FormGroup {
  id: string;
  name: string;
  type: FormGroupType;
  fields: FormField[];
}

export default function useRegistrationForm({ root }: any) {
  const availableFields: {
    [key in "regular" | "additional"]: FormFieldType[];
  } = {
    regular: [
      "firstName",
      "lastName",
      "email",
      "repeatEmail",
      "phonePrefix",
      "phoneNumber",
      "gender",
      "degree",
      "specializations",
      "licenseNumber",
      "studentNumber",
      "sponsor",
      "country",
      "addressCity",
      "addressPostalCode",
      "addressStreet",
      "institutionName",
      "institutionPosition",
      "institutionCity",
      "institutionPostalCode",
      "institutionStreet",
      "companion",
      "sessionTopicsIds",
    ],
    additional: [
      "phone",
      "number",
      "text",
      "textarea",
      "radioGroup",
      "checkboxGroup",
      "select",
      "informationField",
    ],
  };

  const fieldTypes = computed<
    {
      [key in FormFieldType]: {
        name: string;
        icon: string;
        defaultLabel: string;
        protected?: boolean;
        required?: boolean;

        hasItems?: boolean;
        defaultItems?: any[];
        editableItems?: boolean;
      };
    }
  >(() => ({
    firstName: {
      name: root.$tc("layout.registrationFields.participantName"),
      icon: "mdi-account",
      defaultLabel: root.$tc("layout.misc.firstName"),
      protected: true,
      required: true,
    },
    lastName: {
      name: root.$tc("layout.registrationFields.participantLastName"),
      icon: "mdi-account",
      defaultLabel: root.$tc("layout.misc.lastName"),
      protected: true,
      required: true,
    },
    email: {
      name: root.$tc("layout.registrationFields.participantEmail"),
      icon: "mdi-email",
      defaultLabel: root.$tc("layout.misc.email"),
      protected: true,
      required: true,
    },
    repeatEmail: {
      name: root.$tc("layout.registrationFields.repeatParticipantEmail"),
      icon: "mdi-email",
      defaultLabel: root.$tc("layout.misc.repeatEmail"),
      protected: true,
      required: true,
    },
    phonePrefix: {
      name: root.$tc("layout.registrationFields.directional"),
      icon: "mdi-phone-plus",
      defaultLabel: root.$tc("layout.registrationFields.directional"),
      protected: true,
    },
    phoneNumber: {
      name: root.$tc("layout.registrationFields.participantPhone"),
      icon: "mdi-phone",
      defaultLabel: root.$tc("layout.misc.phone"),
      protected: true,
    },
    gender: {
      name: root.$tc("layout.misc.salutation"),
      icon: "mdi-account-multiple",
      defaultLabel: root.$tc("layout.misc.salutation"),
      protected: true,
      hasItems: true,
      defaultItems: [
        { id: uuid(), value: "Pan", text: "Pan" },
        { id: uuid(), value: "Pani", text: "Pani" },
      ],
    },
    degree: {
      name: root.$tc("layout.registrationFields.participantDegree"),
      icon: "mdi-school",
      defaultLabel: root.$tc("layout.misc.degree"),
      protected: true,
    },
    specializations: {
      name: root.$tc("layout.registrationFields.participantSpecialization"),
      icon: "mdi-account-star",
      defaultLabel: root.$tc("layout.misc.specialization"),
      protected: true,
    },
    licenseNumber: {
      name: root.$tc("layout.misc.licenseNumber"),
      icon: "mdi-card-account-details",
      defaultLabel: root.$t("layout.registrationFields.licenseNumber"),
      protected: true,
    },
    studentNumber: {
      name: root.$tc("layout.registrationFields.studentAlbumNumber"),
      icon: "mdi-card-account-details",
      defaultLabel: root.$tc("layout.registrationFields.studentAlbum"),
      protected: true,
    },
    sponsor: {
      name: root.$tc("layout.misc.sponsor"),
      icon: "mdi-handshake-outline",
      defaultLabel: root.$tc("layout.misc.sponsor"),
      protected: true,
    },
    country: {
      name: root.$tc("layout.registrationFields.participantCountry"),
      icon: "mdi-earth",
      defaultLabel: root.$tc("layout.misc.country"),
      protected: true,
    },
    addressCity: {
      name: root.$tc("layout.registrationFields.participantCity"),
      icon: "mdi-city",
      defaultLabel: root.$tc("layout.misc.city"),
      protected: true,
    },
    addressPostalCode: {
      name: root.$tc("layout.registrationFields.participantPostalCode"),
      icon: "mdi-post",
      defaultLabel: root.$tc("layout.misc.postalCode"),
      protected: true,
    },
    addressStreet: {
      name: root.$tc("layout.registrationFields.participantAddress"),
      icon: "mdi-map-marker",
      defaultLabel: root.$tc("layout.misc.streetAndNumber"),
      protected: true,
    },

    institutionName: {
      name: root.$tc("layout.registrationFields.participantInstitution"),
      icon: "mdi-office-building",
      defaultLabel: root.$tc("layout.misc.institution"),
      protected: true,
    },
    institutionPosition: {
      name: root.$tc(
        "layout.registrationFields.participantInstitutionPosition"
      ),
      icon: "mdi-briefcase",
      defaultLabel: root.$tc("layout.misc.position"),
      protected: true,
    },
    institutionCity: {
      name: root.$tc("layout.registrationFields.participantInstitutionCity"),
      icon: "mdi-city",
      defaultLabel: root.$tc("layout.misc.city"),
      protected: true,
    },
    institutionPostalCode: {
      name: root.$tc(
        "layout.registrationFields.participantInstitutionPostalCode"
      ),
      icon: "mdi-post",
      defaultLabel: root.$tc("layout.misc.postalCode"),
      protected: true,
    },
    institutionStreet: {
      name: root.$tc("layout.registrationFields.participantInstitutionAddress"),
      icon: "mdi-map-marker",
      defaultLabel: root.$tc("layout.misc.streetAndNumber"),
      protected: true,
    },
    companion: {
      name: root.$tc("layout.registrationFields.companion"),
      icon: "mdi-account-plus-outline",
      defaultLabel: root.$tc("layout.misc.companion"),
      protected: true,
    },

    invoicePurchaser: {
      name: root.$tc("layout.registrationFields.buyerType"),
      icon: "mdi-call-merge",
      defaultLabel: root.$tc("layout.registrationFields.buyerType"),
      protected: true,
      hasItems: true,
      defaultItems: [
        {
          id: uuid(),
          value: "individual",
          text: root.$tc("participant.invoiceType.individual"),
        },
        {
          id: uuid(),
          value: "institutional",
          text: root.$tc("participant.invoiceType.institutional"),
        },
      ],
      editableItems: false,
    },
    invoiceName: {
      name: root.$tc("layout.registrationFields.buyerName"),
      icon: "mdi-office-building",
      defaultLabel: root.$tc("layout.misc.name"),
      protected: true,
    },
    invoiceStreet: {
      name: root.$tc("layout.registrationFields.buyerAddress"),
      icon: "mdi-map-marker",
      defaultLabel: root.$tc("layout.misc.streetAndNumber"),
      protected: true,
    },
    invoicePostalCode: {
      name: root.$tc("layout.registrationFields.buyerPostalCode"),
      icon: "mdi-post",
      defaultLabel: root.$tc("layout.misc.postalCode"),
      protected: true,
    },
    invoiceCity: {
      name: root.$tc("layout.registrationFields.buyerCity"),
      icon: "mdi-city",
      defaultLabel: root.$tc("layout.misc.city"),
      protected: true,
    },
    invoiceVatNumber: {
      name: root.$tc("layout.registrationFields.buyerVatNumber"),
      icon: "mdi-numeric",
      defaultLabel: root.$tc("layout.registrationFields.buyerVatNumber"),
      protected: true,
    },
    invoiceEmail: {
      name: root.$tc("layout.registrationFields.invoiceEmail"),
      icon: "mdi-numeric",
      defaultLabel: root.$tc("layout.registrationFields.invoiceEmail"),
      protected: true,
    },
    invoiceConsent: {
      name: root.$tc("layout.registrationFields.invoiceConsent"),
      icon: "mdi-receipt-text-outline",
      defaultLabel: root.$tc("layout.registrationFields.invoiceConsentLabel"),
      protected: true,
    },
    invoiceProforma: {
      name: root.$tc("layout.registrationFields.invoiceProFormaConsent"),
      icon: "mdi-receipt-text-outline",
      defaultLabel: root.$tc(
        "layout.registrationFields.invoiceProFormaConsentLabel"
      ),
      protected: true,
    },
    electronicInvoiceConsent: {
      name: root.$tc("layout.registrationFields.eInvoiceConsent"),
      icon: "mdi-receipt-text-outline",
      defaultLabel: root.$tc("layout.registrationFields.eInvoiceConsentLabel"),
      protected: true,
    },
    sessionTopicsIds: {
      name: root.$tc("layout.registrationFields.reviewTopic"),
      icon: "mdi-subtitles-outline",
      defaultLabel: root.$tc("layout.registrationFields.reviewTopicLabel"),
      protected: true,
    },

    phone: {
      name: root.$tc("layout.misc.phoneNumber"),
      icon: "mdi-phone",
      defaultLabel: root.$tc("layout.misc.phoneNumber"),
    },
    number: {
      name: root.$tc("layout.registrationFields.numberField"),
      icon: "mdi-numeric",
      defaultLabel: root.$tc("layout.registrationFields.numberField"),
    },

    text: {
      name: root.$tc("layout.registrationFields.textField"),
      icon: "mdi-form-textbox",
      defaultLabel: root.$tc("layout.registrationFields.textField"),
    },
    textarea: {
      name: root.$tc("layout.registrationFields.textarea"),
      icon: "mdi-form-textarea",
      defaultLabel: root.$tc("layout.registrationFields.textarea"),
    },
    radioGroup: {
      name: root.$tc("layout.registrationFields.singleChoiceField"),
      icon: "mdi-radiobox-marked",
      defaultLabel: root.$tc("layout.registrationFields.singleChoiceField"),
      hasItems: true,
    },
    checkboxGroup: {
      name: root.$tc("layout.registrationFields.mulitpleChoiceField"),
      icon: "mdi-checkbox-marked-outline",
      defaultLabel: root.$tc("layout.registrationFields.mulitpleChoiceField"),
      hasItems: true,
    },
    select: {
      name: root.$tc("layout.registrationFields.selectField"),
      icon: "mdi-form-select",
      defaultLabel: root.$tc("layout.registrationFields.selectField"),
      hasItems: true,
    },
    informationField: {
      name: root.$tc("layout.registrationFields.infoField"),
      icon: "mdi-information-outline",
      defaultLabel: root.$tc("layout.registrationFields.infoField"),
    },

    consent: {
      name: root.$tc("layout.misc.consent"),
      icon: "mdi-information-outline",
      defaultLabel: root.$tc("layout.misc.consent"),
    },
  }));

  const isFieldValid = (
    field: FormField,
    fields: FormField[] = []
  ): boolean => {
    let valid = true;

    // jeżeli pole nie ma nazwy
    if (!field.name) valid = false;

    // jeżeli pole nie ma etykiety
    if (!field.label) valid = false;

    // jeżeli pole należy do pól dodatkowych
    if (availableFields.additional.includes(field.type)) {
      // jeżeli nazwa pola jest typem w grupie pól podstawowych
      if (availableFields.regular.includes(field.name as FormFieldType))
        valid = false;
    }

    // jeżeli pole powinno mieć opcje wyboru
    if (fieldTypes.value[field.type] && fieldTypes.value[field.type].hasItems) {
      // jeżeli pole nie posiada opcji wyboru
      if (!field.options.items || !field.options.items.length) {
        valid = false;
        // jeżeli pole posiada opcje wyboru
      } else {
        // sprawdzenie opcji
        for (const item of field.options.items) {
          // jeżeli opcja nie ma etykiety
          if (!item.text) valid = false;

          // jeżeli opcja nie ma wartości
          if (!item.value) valid = false;
        }
      }
    }

    // jeżeli podano listę wszystkich pól
    if (fields) {
      // porównywanie z innymi polami
      for (const f of fields) {
        // jeżeli nazwa pola się powtarza
        if (f.id !== field.id && f.name === field.name) valid = false;
      }
    }

    return valid;
  };

  return { availableFields, fieldTypes, isFieldValid };
}
